// extracted by mini-css-extract-plugin
export var accordion = "index-module--accordion--3hfFn";
export var accordionButton = "index-module--accordion-button--22TSh";
export var accordionPanel = "index-module--accordion-panel--2nqiR";
export var fadein = "index-module--fadein--2YeVF";
export var videoWrapper = "index-module--video-wrapper--3KfkL";
export var videoOverlay = "index-module--video-overlay--StWoE";
export var hidden = "index-module--hidden--U2G5C";
export var erased = "index-module--erased--3UPjJ";
export var controlsWrapper = "index-module--controls-wrapper--1JjSd";
export var startButton = "index-module--start-button--f9L-J";
export var invisible = "index-module--invisible--1en4v";
export var visible = "index-module--visible--1OmWw";