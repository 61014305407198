import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Seo from '../components/Seo';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import {
	accordion,
	accordionButton,
	accordionPanel,
	videoWrapper,
	videoOverlay,
	controlsWrapper,
	startButton,
	erased,
	invisible,
} from './index.module.css';
import Video from '../assets/Juerss-Zivilcourage-20210929-Farbigkeit.mp4';
import subtitlesEN from 'file-loader!../assets/Die_Entscheidung_EN_subs.vtt';
import subtitlesPL from 'file-loader!../assets/Die_Entscheidung_PL_subs.vtt';

const calcVideoSize = (viewportWidth, viewportHeight) => {
	if (viewportWidth / viewportHeight > 1920 / 1080) {
		const width = (viewportHeight / 9) * 16;
		const height = viewportHeight;
		return { width: width, height: height };
	} else {
		const width = viewportWidth;
		const height = (viewportWidth / 16) * 9;
		return { width: width, height: height };
	}
};

// markup
const IndexPage = ({ data }) => {
	const [videoSize, setVideoSize] = useState({ width: 1920, height: 1080 });

	const [videoStarted, setVideoStarted] = useState(false);
	const [videoTransition, setVideoTransition] = useState(false);
	const [videoPlaying, setVideoPlaying] = useState(false);

	const videoOverlayClass = classNames({
		[videoOverlay]: true,
		[invisible]: videoTransition,
		[erased]: videoPlaying,
	});

	/* const videoClass = classNames({
		[videoInvisible]: !videoPlaying,
		[videoVisible]: videoPlaying,
	}); */

	const controlsWrapperClass = classNames({
		[controlsWrapper]: true,
		[invisible]: videoStarted,
	});

	const h1Class = classNames({
		[invisible]: videoStarted,
	});

	useEffect(() => {
		let vw = window.innerWidth * 0.01;
		let vh = window.innerHeight * 0.01;

		document.documentElement.style.setProperty('--vh', `${vh}px`);
		document.documentElement.style.setProperty('--vw', `${vw}px`);

		const videoSize = calcVideoSize(window.innerWidth, window.innerHeight);
		setVideoSize(videoSize);

		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			let vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			document.documentElement.style.setProperty('--vw', `${vw}px`);
			const videoSize = calcVideoSize(window.innerWidth, window.innerHeight);
			setVideoSize(videoSize);
		});
	}, []);

	const startVideo = () => {
		setVideoStarted(true);
		document.getElementById('video').play();
		setTimeout(function () {
			setVideoTransition(true);
			setTimeout(function () {
				setVideoPlaying(true);
			}, 1000);
		}, 1000);
	};

	return (
		<main>
			<Seo />
			<div className={videoWrapper}>
				<div id="videoOverlay" className={videoOverlayClass}>
					<h1 className={h1Class}>
						<span>DIE ENTSCHEIDUNG</span> – Wilm führte ein Doppelleben
					</h1>
					<div className={controlsWrapperClass}>
						<p>Bitte vergewissern Sie sich, dass der Ton angeschaltet ist.</p>
						<button className={startButton} onClick={startVideo}>
							Video starten
						</button>
					</div>
				</div>
				<video
					id="video"
					width={videoSize.width}
					height={videoSize.height}
					controls
				>
					<source src={Video} type="video/mp4" />
					<track
						src={subtitlesEN}
						kind="captions"
						srclang="en"
						label="English"
					></track>
					<track
						src={subtitlesPL}
						kind="captions"
						srclang="pl"
						label="Polski"
					></track>
					Your browser does not support the video tag.
				</video>
			</div>
			<Accordion
				className={accordion}
				allowZeroExpanded="true"
				allowMultipleExpanded="true"
			>
				{data.allMarkdownRemark.edges.map(({ node }, index) => (
					<AccordionItem key={index}>
						<AccordionItemHeading>
							<AccordionItemButton className={accordionButton}>
								{node.frontmatter.title}
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel
							className={accordionPanel}
							dangerouslySetInnerHTML={{ __html: node.html }}
						/>
					</AccordionItem>
				))}
			</Accordion>
		</main>
	);
};

export default IndexPage;

export const query = graphql`
	{
		allMarkdownRemark(sort: { fields: frontmatter___position, order: ASC }) {
			edges {
				node {
					frontmatter {
						title
					}
					html
				}
			}
		}
	}
`;
